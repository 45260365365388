import React from 'react';
import { Route, Routes } from './router';
import { GroupScreen } from '../screen/group';
import { PositionScreen } from '../screen/position';
import { HomeScreen } from '../screen/home';

const AppRoutes = () => {
  return (
  <Routes>
    <Route path={"/"} element={<HomeScreen />} />
    <Route path={"group"} element={<GroupScreen />} />
    <Route path={"position"} element={<PositionScreen />} />
  </Routes>
  )
};

export default AppRoutes;
