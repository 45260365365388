import React from 'react';
import { Text } from "react-native"
import {Link} from "../router"

export const HomeScreen = () => {
  return (
    <>
      <Text>Home</Text>
      <Link to="group">Group Test</Link>
      <Link to="position">Position Text</Link>
    </>
  );
}
