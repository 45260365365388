import {Text} from 'react-native';
import React from 'react';
import { GroupTest } from '../components/GroupTest';

export const PositionScreen = () => {
  return (
    <Text>Position</Text>

  );
}
