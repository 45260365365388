import { getRandomInt } from '../helpers/random';

export type Suit = {
  color: string;
  oneColor: string;
  icon: string;
};
export const useSuits = () => {
  const suitsKeys = ['hearts', 'diamons', 'clubs', 'spades'];
  const suits = {
    hearts: {
      color: 'red',
      oneColor: 'red',
      icon: 'cards-heart',
    } as Suit,
    diamons: { color: 'red', oneColor: 'blue', icon: 'cards-diamond' } as Suit,
    clubs: { color: 'black', oneColor: 'green', icon: 'cards-club' } as Suit,
    spades: { color: 'black', oneColor: 'black', icon: 'cards-spade' } as Suit,
  };

  const getRandomSuits = (): Suit => {
    const key = suitsKeys[getRandomInt(3)];
    return suits[key];
  };
  const getSuit = (key): Suit => {
    return suits[key];
  };
  return {
    suits,
    getRandomSuits,
    getSuit,
  };
};
