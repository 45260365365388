import React, { useEffect, useState } from 'react';
import styled from "styled-components/native";
import { useCards} from "../hooks/useCards";
import { useGroupRange } from '../hooks/useGroupRange';
import { useDeck } from '../hooks/useDeck';
import { Card } from '../components/Card';
import { FAB,Text, Snackbar } from 'react-native-paper';

export const GroupTest = () => {

  const deck = useDeck()
  const [result, setResult] = useState({acertos: 0, errors: 0});
  const [isWrong, setIsWrong] = useState(false);
  const { cards, getCards } = useCards(deck);
  const { checkGroup } = useGroupRange();


  const [visible, setVisible] = React.useState(false);
  const [lastAnwser, setLastAnwser] = React.useState(false);

  const onToggleSnackBar = (value) => {
    setVisible(!visible)
    setLastAnwser(value)
  };

  const onDismissSnackBar = () => setVisible(false);


  useEffect(() => {
    getCards(2)
  },[])

  const chechByNumber = (group: number) => {
    if(checkGroup(cards, group)){
      onToggleSnackBar(true)
      getCards(2)


    setIsWrong(false)
      setResult(prev => ({
        ...prev,
        acertos: prev.acertos + 1
      }))
    }
    else{
      onToggleSnackBar(false)
      if(!isWrong){

        setResult(prev => ({
          ...prev,
          errors: prev.errors + 1
        }))

        setIsWrong(true)
      }
    }
  }

  return (
    <Container>
      <Cards>
        <Title  color={"green"} variant="headlineLarge">Acertos {result.acertos}</Title>
        <Title color={"red"} variant="headlineLarge">Errors {result.errors}</Title>
      </Cards>
      <Cards>
        {cards.map((card) => <Card card={card} />)}
      </Cards>
      <Row>
        <FAB icon="numeric-1" size="large" onPress={() => chechByNumber(1)} />
        <FAB icon="numeric-2" size="large" onPress={() => chechByNumber(2)} />
        <FAB icon="numeric-3" size="large" onPress={() => chechByNumber(3)} />
      </Row>
      <Row>
        <FAB icon="numeric-4" size="large" onPress={() => chechByNumber(4)} />
        <FAB icon="numeric-5" size="large" onPress={() => chechByNumber(5)} />
        <FAB icon="numeric-6" size="large" onPress={() => chechByNumber(6)} />
      </Row>
      <Row>
        <FAB icon="numeric-7" size="large" onPress={() => chechByNumber(7)} />
        <FAB icon="numeric-8" size="large" onPress={() => chechByNumber(8)} />
        <FAB icon="close" size="large"  onPress={() => chechByNumber(0)} />
      </Row>
      <Snackbar
        visible={visible}
        onDismiss={onDismissSnackBar}
        >
        {lastAnwser ? "Acertou!" : "Errou tente novamente até acertar"}
      </Snackbar>
    </Container>
  );
}
const Container = styled.View`
  flex:1
`
const Cards = styled.View`
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  gap:20px
`;


const Row = styled.View`
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap:20px;
  margin-bottom: 20px
`
const Title = styled(Text)`
  color:${({color}) => color || "black"}
`
