import React from 'react';
import AppRoutes from './router/app-routing';
import { Router } from './router';

export const App = () => {

  return <Router>
    <AppRoutes />
  </Router>
}
