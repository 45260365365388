import { arraysEqual } from '../helpers/arrayEqual';

export const useGroupRange = () => {
  const groups = {
    1: [
      ['A', 'K', 's'],
      ['A', 'A', 'o'],
      ['K', 'K', 'o'],
      ['Q', 'Q', 'o'],
      ['J', 'J', 'o'],
    ],
    2: [
      ['T', 'T', 'o'],
      ['A', 'Q', 's'],
      ['A', 'J', 's'],
      ['K', 'Q', 's'],
      ['A', 'K', 'o'],
    ],
    3: [
      ['9', '9', 'o'],
      ['A', 'T', 's'],
      ['K', 'J', 's'],
      ['Q', 'J', 's'],
      ['J', 'T', 's'],
      ['A', 'Q', 'o'],
    ],
    4: [
      ['8', '8', 'o'],
      ['K', 'T', 's'],
      ['Q', 'T', 's'],
      ['J', '9', 's'],
      ['T', '9', 's'],
      ['8', '9', 's'],
      ['A', 'J', 'o'],
      ['K', 'Q', 'o'],
    ],
    5: [
      ['7', '7', 'o'],
      ['A', '9', 's'],
      ['A', '8', 's'],
      ['A', '7', 's'],
      ['A', '6', 's'],
      ['A', '5', 's'],
      ['A', '4', 's'],
      ['A', '3', 's'],
      ['A', '2', 's'],
      ['Q', '9', 's'],
      ['T', '8', 's'],
      ['9', '7', 's'],
      ['8', '7', 's'],
      ['7', '6', 's'],
      ['K', 'J', 'o'],
      ['Q', 'J', 'o'],
      ['J', 'T', 'o'],
    ],
    6: [
      ['6', '6', 'o'],
      ['5', '5', 'o'],
      ['K', '9', 's'],
      ['J', '8', 's'],
      ['8', '6', 's'],
      ['7', '5', 's'],
      ['5', '4', 's'],
      ['A', 'T', 'o'],
      ['K', 'T', 'o'],
      ['Q', 'T', 'o'],
    ],
    7: [
      ['4', '4', 'o'],
      ['3', '3', 'o'],
      ['2', '2', 'o'],
      ['K', '8', 's'],
      ['K', '7', 's'],
      ['K', '6', 's'],
      ['K', '5', 's'],
      ['K', '4', 's'],
      ['K', '3', 's'],
      ['K', '2', 's'],
      ['Q', '8', 's'],
      ['T', '7', 's'],
      ['6', '4', 's'],
      ['5', '3', 's'],
      ['4', '3', 's'],
      ['J', '9', 'o'],
      ['T', '9', 'o'],
      ['9', '8', 'o'],
    ],
    8: [
      ['J', '7', 's'],
      ['9', '6', 's'],
      ['8', '5', 's'],
      ['7', '4', 's'],
      ['4', '2', 's'],
      ['3', '2', 's'],
      ['A', '9', 'o'],
      ['K', '9', 'o'],
      ['Q', '9', 'o'],
      ['J', '8', 'o'],
      ['T', '8', 'o'],
      ['8', '7', 'o'],
      ['7', '6', 'o'],
      ['6', '5', 'o'],
      ['5', '4', 'o'],
    ],
  };

  const handsWithGroup = Object.values(groups).reduce((acc, item) => {
    return [...acc, ...item];
  }, []);

  const checkHandsXCombos = (hands, combo1, combo2) => {
    return hands.reduce((response, item) => {
      if (arraysEqual(item, combo1) || arraysEqual(item, combo2)) {
        return true;
      }

      return response;
    }, false);
  };

  const checkNoGroup = (combo1, combo2) => {
    return !checkHandsXCombos(handsWithGroup, combo1, combo2);
  };

  const checkGroup = (cards: [], group) => {
    const suited = cards[0][1] == cards[1][1] ? 's' : 'o';
    const card1 = cards[0][0];
    const card2 = cards[1][0];

    const combo1 = [card1, card2, suited];
    const combo2 = [card2, card1, suited];

    if (group === 0) {
      return checkNoGroup(combo1, combo2);
    }
    const hands = groups[group];

    return checkHandsXCombos(hands, combo1, combo2);
  };
  return {
    groups,
    checkGroup,
    handsWithGroup,
  };
};
