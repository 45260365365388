interface Card {
  suit: string;
  symbol: string;
}

import { useState, useMemo, useCallback } from 'react';
import { useDeck } from './useDeck';
//
export const useCards = (deck) => {
  const { getCardsFromDeck } = deck;
  const [cards, setCards] = useState<Cards[]>([]);

  const getCards = useCallback((total: number) => {
    const newCards = getCardsFromDeck(total);
    setCards(newCards);
    return newCards;
  }, []);

  const reset = () => {
    setCards([]);
  };

  return {
    reset,
    cards,
    getCards,
  };
};
