export const useSymbols = () => {
  const symbols = {
    A: 'A',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    T: 'T',
    J: 'J',
    Q: 'Q',
    K: 'K',
  };
  return {
    symbols,
  };
};
