import React from 'react';
import styled, { css} from "styled-components/native";
import { useSuits } from '../hooks/useSuits';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export const Card = ({card}) => {

  const {getSuit } = useSuits();
  const suit = getSuit(card[1]);

  return (
  <Container backgroundColor={suit.oneColor}>
    <Icon name={suit.icon} />
    <Wrapper>
      <Symbol>
        {card[0]}
      </Symbol>
    </Wrapper>
    <Icon isInverted name={suit.icon} />
  </Container>
  )
}

const Wrapper = styled.View`
  border:1px solid #fff;
  flex:1;
  width:100%;
  justify-content: center;
  align-items: center;
  z-index: -1;
`
const Symbol = styled.Text`
  font-size: 45;
  color: white;
`
const Container = styled.View`
  width: 100px;
  height:150px;
  background:${({backgroundColor}) => backgroundColor};
  overflow: hidden;
  border-radius: 10px;
  border:1px solid #333;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px;
`
const Icon = styled(MaterialCommunityIcons).attrs(({name}) => ({
  name,
  size: 20,
  color: "white"
}))`
  position: absolute;
  ${({isInverted}) =>
    isInverted ?
      css`
        top: 5px;
        left: 5px`
    :
      css`
        bottom: 5px;
        right: 5px;
        transform: rotate(180deg);
    `
}

`
