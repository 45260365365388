import { useMemo, useState } from 'react';
import { useSuits } from './useSuits';
import { useSymbols } from './useSymbols';
import { getRandomInt } from '../helpers/random';

const TOTAL_DECK = 52;
export const useDeck = () => {
  const { suits } = useSuits();
  const { symbols } = useSymbols();

  const [usedCards, setUsedCards] = useState([] as number[]);

  const deck = useMemo(() => {
    const cards = [] as any[];
    Object.keys(suits)?.map((suit) => {
      Object.values(symbols)?.map((symbol) => {
        cards.push([symbol, suit]);
      });
    });
    return cards;
  }, [suits, symbols]);

  const checkIfIsUsed = (id: number): boolean => {
    return usedCards.includes(id);
  };
  const getCard = (): number | null => {
    if (usedCards.length === TOTAL_DECK) {
      return null;
    }
    const id = getRandomInt(TOTAL_DECK - 1);
    if (checkIfIsUsed(id)) {
      return getCard();
    } else {
      return id;
    }
  };
  const getCardsFromDeck = (quantity: number) => {
    const cards = [];
    const currentsIds = [] as number[];

    while (cards.length < quantity) {
      const id = getCard();

      if (id && !currentsIds.includes(id)) {
        setUsedCards((prev) => [...prev, id]);

        currentsIds.push(id);

        cards.push(deck[id]);
      }
    }
    return cards;
  };

  return {
    deck,
    usedCards,
    getCardsFromDeck,
  };
};
